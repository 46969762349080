@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
.clrfx::before,
.clrfx::after {
  content: "";
  display: block;
  clear: both;
}
body {
  background: #e6e8ea;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  min-height: 100vh;
  padding-bottom: 70px;
  position: relative;
}
.clrfx::before,
.clrfx::after {
  content: "";
  display: block;
  clear: both;
}
a,
.pseudo-link {
  color: #6b9bd2;
  text-decoration: underline;
}
img {
  max-width: 100%;
}
.avatar::before {
  background: #9d2727;
}
.avatar.online::before {
  background: #99ca3b;
}
@media screen and (min-width: 1169px) {
  a,
  .pseudo-link {
    cursor: pointer;
  }
  a:hover,
  .pseudo-link:hover {
    color: #96a0ab;
    text-decoration: none;
  }
}
.btn {
  display: block;
  font-size: 15px;
  line-height: 20px;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
}
.btn.disabled {
  cursor: default;
  opacity: 0.5;
}
.btn.medium {
  font-size: 13px;
  height: 34px;
  padding: 7px 15px;
}
.btn.medium.transparent {
  padding: 6px 14px;
}
.btn.round {
  border-radius: 6px;
}
.btn.btn-green {
  border-color: #99ca3b;
  background: #99ca3b;
  color: #fff;
}
.btn.btn-blue {
  border-color: #6999cf;
  background: #6999cf;
  color: #fff;
}
.btn.btn-red {
  border-color: #9d2727;
  background: #9d2727;
  color: #fff;
}
.btn.btn-white {
  border-color: #fff;
  background: #fff;
  color: #000;
}
.btn.transparent {
  border: 1px solid;
  padding: 14px 29px;
  background: transparent;
}
.btn.transparent.btn-green {
  color: #99ca3b;
}
.btn.transparent.btn-blue {
  color: #6999cf;
}
.btn.transparent.btn-red {
  color: #9d2727;
}
.btn.inline {
  display: inline-block;
}
@media screen and (min-width: 1179px) {
  .btn {
    cursor: pointer;
  }
  .btn:hover {
    color: #fff !important;
    opacity: 0.7;
  }
  .btn.btn-white:hover {
    color: #000 !important;
  }
}
.icon.timestamp-icon::before {
  content: url("data:image/svg+xml;utf-8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0,0,8,8'%3E%3Cpath%20fill='none'%20stroke='%23acb4bd'%20d='m.5%201.5h7v6h-7zm1-1.5v1.5m5-1.5v1.5m-4.5%202h4m-4%202h3'/%3E%3C/svg%3E");
  width: 8px;
  height: 8px;
}
.icon.comments-small-icon::before {
  content: url("data:image/svg+xml;utf-8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0,0,11,11'%3E%3Cpath%20fill='none'%20stroke='%23c0c5cd'%20d='m3.5%209-2%201%20.5-2.2a5%204.5%200%200%201-1.5-2.8%205%204.5%200%200%201%2010%200%205%204.5%200%200%201-7%204zm-.5-5h5m-.75%202h-3.5'/%3E%3C/svg%3E");
  width: 11px;
  height: 11px;
}
.icon.edit-icon::before {
  content: url("data:image/svg+xml;utf-8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0,0,14,14'%3E%3Cpath%20fill='none'%20stroke='%236b9bd2'%20stroke-linejoin='round'%20d='m.5%2013.5v-4l9-9%204%204-9%209zm.5-4.5h2.5v1.5h1.5v2.5m8-8-2-.5-1.5-1.5%200-2m0%202-6%206m1.5%201.5%206-6m-10.5%207.5%201.5%201.5'/%3E%3C/svg%3E");
  width: 14px;
  height: 14px;
}
.icon.delete-icon::before {
  content: url("data:image/svg+xml;utf-8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0,0,11,11'%3E%3Cpath%20fill='none'%20stroke-width='2'%20stroke='%239d2727'%20d='m.75%20.75%209%209m0-9-9%209'/%3E%3C/svg%3E");
  width: 14px;
  height: 14px;
}
.formatted-content iframe {
  display: block;
  width: 100%;
  height: calc(100vw * 0.55);
}
.formatted-content img {
  max-width: 100%;
  height: auto;
}
.formatted-content p {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.formatted-content p:last-child {
  margin-bottom: 0;
}
.formatted-content h2,
.formatted-content h3 {
  color: #6b9bd2;
  font-weight: 600;
  margin-bottom: 20px;
}
.formatted-content h2:last-child,
.formatted-content h3:last-child {
  margin-bottom: 0;
}
.formatted-content h2 {
  font-size: 18px;
}
.formatted-content h3 {
  font-size: 16px;
  font-style: italic;
}
.formatted-content ol,
.formatted-content ul {
  padding-left: 45px;
  list-style: none;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.formatted-content ol:last-child,
.formatted-content ul:last-child {
  margin-bottom: 0;
}
.formatted-content ol li,
.formatted-content ul li {
  position: relative;
  margin-bottom: 10px;
}
.formatted-content ul li::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #6b9bd2;
  position: absolute;
  border-radius: 50%;
  left: -25px;
  top: 8px;
}
.formatted-content ol {
  counter-reset: li;
}
.formatted-content ol li {
  counter-increment: li;
}
.formatted-content ol li::before {
  content: counter(li) ".";
  position: absolute;
  left: -25px;
  color: #6b9bd2;
  font-weight: 700;
}
.formatted-content figure.image.align-left,
.formatted-content img.align-left {
  float: left;
  margin-right: 20px;
}
.formatted-content figure.image.align-right,
.formatted-content img.align-right {
  float: right;
  margin-left: 20px;
}
.formatted-content figure.image.align-center,
.formatted-content img.align-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.formatted-content figure.image {
  border: 0;
  padding: 0;
  background: none;
}
.formatted-content figure.image figcaption {
  padding: 0 10px 10px;
  font-style: italic;
  font-size: 0.9em;
  text-align: center;
  margin: 0;
}
.formatted-content a {
  color: #6b9bd2;
}
.formatted-content a:hover {
  color: #96a0ab;
  text-decoration: none;
}
.formatted-content blockquote {
  font-size: 15px;
  line-height: 1.5;
  padding: 10px 30px;
  border-left: 4px solid #99ca3b;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
          box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
}
.formatted-content blockquote:last-child {
  margin-bottom: 0;
}
.formatted-content table {
  border-collapse: collapse;
}
.formatted-content table thead {
  background: #f2fbff;
}
.formatted-content table td,
.formatted-content table th {
  line-height: 1.5;
  padding: 15px;
  border: 1px solid #e2e9ed;
}
.formatted-content table td {
  font-size: 15px;
}
.formatted-content table th {
  color: #6b9bd2;
  font-size: 16px;
  font-weight: 400;
}
.formatted-content .spoiler {
  margin-bottom: 20px;
}
.formatted-content .spoiler .spoiler-title {
  background: #4971a0;
  color: #fff;
  display: block;
  font-size: 20px;
  padding: 5px 10px 5px 40px;
  cursor: pointer;
  position: relative;
}
.formatted-content .spoiler .spoiler-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  border-top: 10px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 0;
  margin-top: -4px;
}
.formatted-content .spoiler .spoiler-content {
  border: 1px solid #4971a0;
  padding: 10px;
  border-top: 0;
  display: none;
}
.formatted-content .spoiler > input {
  display: none;
}
.formatted-content .spoiler > input:checked + .spoiler-title::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.formatted-content .spoiler > input:checked + .spoiler-title + .spoiler-content {
  display: block;
}
.formatted-content .spoiler:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 1179px) {
  .formatted-content iframe {
    height: 450px;
  }
}
body {
  text-align: center;
  padding: 100px 0;
}
body form {
  display: inline-block;
  width: 300px;
  border-radius: 8px;
  background: #fff;
  padding: 15px 20px;
}
body form h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}
body form input {
  width: 100%;
  font-size: 20px;
  line-height: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
body form button {
  width: 100%;
  margin-bottom: 7px;
}
body form button+a {
  color: #99ca3b;
}
body form p {
  color: #9d2727;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}
body .recovery-instructions {
  max-width: 900px;
  margin: auto;
  padding: 10px;
  border: 2px solid #99ca3b;
}